
    import Vue from 'vue';

    import RCP from '@/api/RCP.ts';

    import GenerateFile from '@/components/generatefile/GenerateFile.vue';

    import { VersionResponse } from '@/types/Version';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'card',
        props: {
            version: {
                type: Object,
                required: true
            },
            templateId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                request: false,
                generateFile: false
            };
        },
        methods: {
            publishVersion(id: number) {
                if (!this.request) {
                    this.request = true;

                    RCP({
                        method: 'App.Template.Version.publish',
                        params: {
                            id,
                            template_id: +this.templateId
                        },
                        id: 'publishVersion'
                    }).then((result: VersionResponse) => {
                        this.request = false;

                        if (result.item) {
                            this.$store.commit('template/setVersion', result.item);

                            this.$emit('update');
                            alert('Опубликовано : Шаблон успешно опубликован');
                        } else {
                            alert('Не удалось опубликовать шаблон : Произошла ошибка при попытке опубликовать шаблон');
                        }

                    }).catch((error: ErrorResponse) => {
                        this.request = false;

                        if (error.show) {
                            alert('Ошибка : ' + error.message);
                        }
                    });
                }
            }
        },
        components: {
            GenerateFile
        }
    });
