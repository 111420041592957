
    import Vue from 'vue';

    import RCP from '@/api/RCP.ts';

    import Field from '@/components/Field.vue';
    import SelectField from '@/components/SelectField.vue';
    import VersionCard from '@/components/versions/Card.vue';
    import Pagination from '@/components/Pagination.vue';

    import { Version, VersionsResponse } from '@/types/Version';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'versions',
        data() {
            return {
                versions: [] as Version[],
                loading: false,
                error: false,
                request: false,
                searchAllow: false,
                search: '',
                delayTimer: 0,
                pagination: {
                    limit: 5,
                    currentPage: 1,
                    totalItems: 0
                },
                filter: {
                    sort: 'desc'
                },
                filters: {
                    sort: {
                        desc: 'Сначала последние',
                        asc: 'Сначала первые'
                    }
                }
            };
        },
        created() {
            const page = +this.$route.params.page || -1;

            this.getVersions(page);
        },
        computed: {
            templateId(): number {
                return +this.$route.params.id || -1;
            }
        },
        watch: {
            $route() {
                const page = +this.$route.params.page || -1;

                this.getVersions(page, this.search);
            },
            search(value) {
                this.searchDelay(value);
            },
            filter: {
                handler() {
                    this.getVersions(1, this.search);
                },
                deep: true
            }
        },
        methods: {
            getVersions(page = -1, search: any = '') {
                if (!this.request) {
                    if (page === -1) {
                        page = this.pagination.currentPage;
                    }

                    this.request = true;
                    this.versions = [];
                    this.error = false;
                    this.loading = false;

                    const id = this.templateId;

                    const filter: any = {
                        template_id: ['==', id]
                    };

                    if (search) {
                        filter.$or = [{
                            file_name: ['like', `%${search}%`]
                        }, {
                            description: ['like', `%${search}%`]
                        }];

                        if (search && !isNaN(search)) {
                            const version = {
                                version: ['==', +search]
                            };

                            filter.$or.push(version);
                        }
                    }

                    const params: any = {
                        select: ['id', 'generator_file_out_format', 'publish', 'version', 'description', 'file_name', 'generator_file', 'created_at', 'publish_at', 'created_by_user_id', 'publish_by_user_id', 'CreatedByUser', 'CreatedByUser.login', 'PublishByUser', 'PublishByUser.login'],
                        limit: this.pagination.limit,
                        offset: this.pagination.limit * (page - 1),
                        order: [
                            ['id', this.filter.sort]
                        ],
                        filter
                    };

                    RCP({
                        method: 'App.Template.Version.list',
                        params,
                        id: `getVersions-${id}`
                    }).then((result: VersionsResponse) => {
                        if (result.items) {
                            this.versions = result.items;
                            this.pagination.currentPage = page;
                            this.pagination.totalItems = result.total_count;

                            if (this.versions.length > 0 || search) {
                                this.searchAllow = true;
                            } else {
                                this.searchAllow = false;
                            }

                            if (search !== this.search) {
                                this.searchDelay(this.search);
                            }
                        } else {
                            this.error = true;
                        }

                        this.loading = true;
                        this.request = false;

                        if (this.pagination.totalItems > 0 &&
                            Math.ceil(this.pagination.totalItems / this.pagination.limit) < page) {
                            this.searchDelay(this.search, 0);
                        }
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            alert("Ошибка : " + error.message);
                        }

                        this.error = true;
                        this.loading = true;
                        this.request = false;
                    });
                }
            },
            searchDelay(value: any, time = 500) {
                clearTimeout(this.delayTimer);
                this.delayTimer = setTimeout(() => {
                    this.$router.push(`/template/${this.templateId}/versions/`);
                    this.getVersions(1, value);
                }, time);
            }
        },
        components: {
            Field,
            SelectField,
            VersionCard,
            Pagination
        }
    });
